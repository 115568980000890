import FeaturedProperties from "../featured-properties";
import ListAgents from "../list-agents";
import MediaKit from "../media-kit";
import Partners from "../partners";
import Testimonials from "../testimonials";

const Home: React.FunctionComponent<{}> = (props) => {


      return (
            <div className="homepage-9 hp-6 homepage-1 mh">

                  {/* START SECTION PROFILE NASSIRA */}
                  <MediaKit />

                  <section className="how-it-works bg-black-2 rec-pro">
                        <div className="service-profile-14">
                              <div className="row">
                                    <div className="col-md-12">
                                          <h2 className="title"> A Brand you can</h2>
                                          <h2 className="title font-weight-500">Trust</h2>
                                    </div>
                              </div>
                              <div className=" row box">
                                    <div className="col-md-6 padd-trust">
                                          <img src="/assets/images/Background.png" />
                                    </div>
                                    <div className="col-md-6 padd-trust-1">
                                          <div className="para">
                                                <p> Welcome to the world of Nassira Sekkay, the Wonder Woman
                                                      of Real Estate and her UAE based Real Estate Brokerage
                                                      Nassira Properties. Our media kit showcases Nassira Sekkay,
                                                      her personal brand and her expertise in luxury real estate, her
                                                      clients and the benefits of collaborating with us.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </section >

                  <section className="how-it-works bg-white rec-pro">
                        <div className="container-fluid padd-service-profile">
                              <div className="service-profile-nassira">
                                    <div className="row" style={{display:"flex", justifyContent: "center"}}>
                                          <div className="item col-lg-5 col-md-5 col-xs-12 people" >
                                                <div className="project-single">
                                                      <a className="project-inner project-head" href="https://www.harpersbazaararabia.com/culture/people/who-is-nassira-sekkay-million-dollar-listing-uae" target="_blank">
                                                            <div className="homes">
                                                                  <img src="/assets/images/blog-1.webp" alt="home-1" className="img-responsive" />
                                                            </div>
                                                      </a>
                                                      <a className="homes-content" href="https://www.harpersbazaararabia.com/culture/people/who-is-nassira-sekkay-million-dollar-listing-uae" target="_blank">
                                                            <h3 className="title-h3">Who Is Nassira Sekkay, An Agent On The Million Dollar Listing UAE? </h3>
                                                      </a>
                                                </div>
                                          </div>
                                          <div className="item col-lg-5 col-md-5 col-xs-12 people">
                                                <div className="project-single">
                                                      <a className="project-inner project-head" href="https://www.arabnews.com/node/2373591/lifestyle" target="_blank">
                                                            <div className="homes">
                                                                  <div className="homes-img">
                                                                        <img src="/assets/images/blog-2.webp" alt="home-1" className="img-responsive" />
                                                                  </div>
                                                            </div>
                                                      </a>
                                                      <a className="homes-content" href="https://www.arabnews.com/node/2373591/lifestyle" target="_blank">
                                                            <h3 className="title-h3">‘Million Dollar Listing UAE’ stars on why the Mideast version of the hit US show could be the best</h3>
                                                      </a>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </section>

                  {/* START SECTION FEATURED PROPERTIES */}
                  < FeaturedProperties />

                  <section className="how-it-works bg-white rec-pro">
                        <div className="service-profile-10">
                              <img src="/assets/images/Group 1.png" />
                        </div>
                  </section>


                  {/* START SECTION WHY CHOOSE US */}
                  < section className="how-it-works bg-white rec-pro  padd-top-1" >
                        <div className="container-fluid">
                              <div className="sec-title">
                                    <h2><span>Why </span>Choose Us</h2>
                                    <p>We provide full service at every step.</p>
                              </div>
                              <div className="row service-1">
                                    <article className="col-lg-6 col-md-6 col-xs-12 serv mb-3 pt" >
                                          <div className="serv-flex">
                                                <div className="art-1 img-13">
                                                      <img src="/assets/images/icons/icon-4.svg" alt="" />
                                                      <h3>Wide Renge Of Properties</h3>
                                                </div>
                                                <div className="service-text-p">
                                                      <p className="text-center">Offering a wide range of properties gives potential buyers or renters plenty of options to choose from. Whether it's residential, commercial, or industrial, having diversity in your property listings can attract a broader audience.The company consistently delivers on its promises and provides excellent service to its clients. Word-of-mouth recommendations and positive reviews from satisfied customers contribute to this trust. Building and maintaining trust is crucial in the real estate industry, thats why Nassira Properties has succeeded in doing so.</p>
                                                </div>
                                          </div>
                                    </article>
                                    <article className="col-lg-6 col-md-6 col-xs-12 serv mb-3 pt">
                                          <div className="serv-flex">
                                                <div className="art-1 img-14">
                                                      <img src="/assets/images/icons/icon-5.svg" alt="" />
                                                      <h3>Trusted by thousands</h3>
                                                </div>
                                                <div className="service-text-p">
                                                      <p className="text-center">Being trusted by thousands speaks volumes about Nassira Properties' reputation and track record. It suggests that the company consistently delivers on its promises and provides excellent service to its clients. Word-of-mouth recommendations and positive reviews from satisfied customers likely contribute to this trust. Building and maintaining trust is crucial in the real estate industry, and it seems like Nassira Properties has succeeded in doing so.
                                                            Making financing easy for clients is a significant advantage in the real estate industry. It helps to streamline the buying process, making it more accessible and less stressful for potential buyers. Offering various financing options, providing guidance through the process, and possibly even partnering with financial institutions contribute to making financing easier for clients. This approach makes it  attractive to more buyers and ultimately lead to more successful transactions for Nassira Properties.</p>
                                                </div>
                                          </div>
                                    </article>
                                    <article className="col-lg-6 col-md-6 col-xs-12 serv mb-3 pt">
                                          <div className="serv-flex arrow">
                                                <div className="art-1 img-15">
                                                      <img src="/assets/images/icons/icon-6.svg" alt="" />
                                                      <h3>Financing made easy</h3>
                                                </div>
                                                <div className="service-text-p">
                                                      <p className="text-center"> Offering various financing options, providing guidance through the process, and possibly even partnering with financial institutions contribute to making financing easier for clients. This approach makes it  attractive to more buyers and ultimately lead to more successful transactions for Nassira Properties.</p>
                                                </div>
                                          </div>
                                    </article>
                                    <article className="col-lg-6 col-md-6 col-xs-12 serv mb-3 pt its-2">
                                          <div className="serv-flex">
                                                <div className="art-1 img-14">
                                                      <img src="/assets/images/icons/icon-15.svg" alt="" />
                                                      <h3>We are here near you</h3>
                                                </div>
                                                <div className="service-text-p">
                                                      <p className="text-center">We are accessible and can provide personalized assistance to clients in their local area. Whether  offering property viewings, providing guidance on market trends, or facilitating negotiations, being nearby allows for more efficient communication and support throughout the buying or selling process. This proximity help in  foster trust and strengthen relationships with clients.</p>
                                                </div>
                                          </div>
                                    </article>
                              </div>
                        </div>
                  </section >

                  {/* START SECTION AGENTS */}
                  < ListAgents />

                  {/* START SECTION TESTIMONIALS  */}
                  < Testimonials />

                  {/* STAR SECTION PARTNERS */}
                  <Partners />

            </div >
      )
}
export default Home
      ;
