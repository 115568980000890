import { useEffect, useState } from "react";
import { GetAgents } from "../Services/Serv-properties";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ListAgents: React.FunctionComponent<{}> = () => {


    const [listAgents, setListAgents] = useState<any>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        GetAgents().then((data) => {
            setListAgents(data)
            console.log(data)
        }).catch((error) => {
            console.log("error")
        })

    }, [])

    return (
        <section className="team bg-white rec-pro">
            <div className="container-fluid">
                <div className="sec-title">
                    <h2><span>Meet Our </span>Agents</h2>
                    <p>Our Agents are here to help you</p>
                </div>
                <div className="row team-all" >
                    {listAgents && listAgents.map((agent: any, index: any) => {

                        return <div key={index} className="team-block col-sm-6 col-md-4 col-lg-4 col-xl-2" >
                            <div className="inner-box team-details">
                                <div className="image team-head">
                                    <a href="#"><img src={agent.photo} alt="" style={{height:"190px"}} /></a>
                                    <div className="team-hover">
                                        <ul className="team-social">
                                            <li><a href={`mailto:${agent.emailAddresses}`} className="facebook"><i className="fas fa-at"></i></a></li>
                                            <li><a className="linkedin" onClick={() => handleClick}><i className="fas fa-phone"></i></a></li>
                                            <Popover
                                                key={index}
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Typography sx={{ p: 2 }}>{agent.phoneNumbers}</Typography>
                                            </Popover>
                                        </ul>
                                    </div>
                                </div>
                                <div className="lower-box">
                                    <h3><a href="#">{agent.name}</a></h3>
                                    <div className="designation">{agent.companyName}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </section>
    )
}

export default ListAgents;