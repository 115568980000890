
import { useEffect, useState } from "react"


const MediaKit: React.FunctionComponent<{}> = (props) => {



      return (

            <section className="how-it-works bg-white rec-pro">
                  <div className="container-fluid padd-service-media-kit " >
                        <div className="service-media-kit">

                              <img src="/assets/images/Media -Kit.png" className="img-profile" />

                              <div className="box">
                                    <h2 className="title">Nassira Sekkay</h2>
                                    <video controls>
                                          <source src="/assets/images/7d95601a-769d-4cf9-9bdb-3466fcf2edef.MP4" type='video/mp4' />
                                    </video>
                                    <div className="images">
                                          <img src="/assets/images/arabe-news.png" className="img-4" />
                                          <img src="/assets/images/gulf-news.png" className="img-4" />
                                          <img src="/assets/images/bazaar.png" className="img-4" />
                                          <img src="/assets/images/listing.png" className="img-4" />
                                    </div>
                                    {/* <div className="bg-all">
                                          <a href="/nassira-properties-media-kit" className="btn btn-outline-light">Learn More</a>
                                    </div> */}
                              </div>
                        </div>
                  </div>
            </section >
      )
}
export default MediaKit;