import * as React from 'react';
import { useState } from 'react';

const Header: React.FunctionComponent<{}> = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header id="header-container">
            <div id="header">
                <div className="container container-header">
                    <div className="left-side">
                        <div id="logo">
                            <a href="/home">
                                <img  src="/assets/images/LOGO2.png" alt="" /></a>
                        </div>
                      
                        <div className="mobile-menu-nav">
                        <a onClick={toggleMenu} className="nav-opener text-center md-round"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.5 9.04167H3.5C3.02167 9.04167 2.625 8.645 2.625 8.16667C2.625 7.68834 3.02167 7.29167 3.5 7.29167H24.5C24.9783 7.29167 25.375 7.68834 25.375 8.16667C25.375 8.645 24.9783 9.04167 24.5 9.04167Z" fill="#FF385C" />
                                <path d="M24.5 14.875H3.5C3.02167 14.875 2.625 14.4783 2.625 14C2.625 13.5217 3.02167 13.125 3.5 13.125H24.5C24.9783 13.125 25.375 13.5217 25.375 14C25.375 14.4783 24.9783 14.875 24.5 14.875Z" fill="#FF385C" />
                                <path d="M24.5 20.7083H3.5C3.02167 20.7083 2.625 20.3117 2.625 19.8333C2.625 19.355 3.02167 18.9583 3.5 18.9583H24.5C24.9783 18.9583 25.375 19.355 25.375 19.8333C25.375 20.3117 24.9783 20.7083 24.5 20.7083Z" fill="#FF385C" />
                            </svg>
                            </a>
                            { isOpen  && <div className="nav">
                                <ul>
                                <li><a href="/home">Home</a></li>
                                <li><a href="/properties">Properties</a></li>
                                </ul>
                            </div>}
                        </div>
                        <nav id="navigation" className="style-1">
                            <ul id="responsive">
                                <li><a href="/home">Home</a>
                                </li>

                                <li><a href="/properties">Properties</a>
                                </li>


                            </ul>
                        </nav>

                    </div>


                </div>
            </div>

        </header >
    )
}

export default Header