import { useEffect, useState } from "react"
import { GetFeaturedProperties } from "../Services/Serv-properties";


const FeaturedProperties: React.FunctionComponent<{}> = (props) => {

    const [listProperties, setListProperties] = useState<any>([]);

    useEffect(() => {
        GetFeaturedProperties().then((data) => {
            setListProperties(data)
        }).catch((error) => {
            console.log("error")
        })

    }, [])


    return (
        <section className="featured portfolio bg-white-2 rec-pro full-l">
            <div className="container-fluid">
                <div className="sec-title">
                    <h2><span>Featured </span>Properties</h2>
                    <p>These are our featured properties</p>
                </div>
                <div className="row portfolio-items">
                    {listProperties && listProperties.map((property: any, index: any) => {
                        return <div  key={index} className="item col-xl-6 col-lg-12 col-md-12 col-xs-12 landscapes sale">
                            <div className="project-single">
                                <div className="project-inner project-head">
                                    <div className="homes">
                                        <a href={`/property/${property._id}`} className="homes-img">
                                            <div className="homes-tag button alt featured">Featured</div>
                                            <div className="homes-tag button alt sale">{property.content.offeringType.replace(/-/g, ' ').replace(/\b\w/g, (c: any) => c.toUpperCase())}</div>
                                            <img src={property.content.images[0]} alt="home-1" className="img-responsive"  style={{maxHeight:"270px",minHeight:"270px"}}/>
                                        </a>
                                    </div>
                                    <div className="button-effect">
                                        <a href={`/property/${property._id}`} className="img-poppu btn"><i className="fa fa-photo"></i></a>
                                    </div>
                                </div>
                                <div className="homes-content">
                                    <h3><a href={`/property/${property._id}`}>{property.content.title}</a></h3>
                                    <p className="homes-address mb-3">
                                        <a href={`/property/${property._id}`}>
                                            <i className="fa fa-map-marker"></i><span>{property.content.Address.subCommunity}, {property.content.Address.community}, {property.content.Address.city}</span>
                                        </a>
                                    </p>
                                    <ul className="homes-list clearfix pb-3">
                                        <li className="the-icons">
                                            <i className="flaticon-bed mr-2" aria-hidden="true"></i>
                                            <span>{property.content.bedrooms_count} Bedrooms</span>
                                        </li>
                                        <li className="the-icons">
                                            <i className="flaticon-bathtub mr-2" aria-hidden="true"></i>
                                            <span>{property.content.bathrooms_count} Bathrooms</span>
                                        </li>
                                        <li className="the-icons">
                                            <i className="flaticon-square mr-2" aria-hidden="true"></i>
                                            <span>{property.content.size.value} {property.content.size.unit}</span>
                                        </li>

                                    </ul>
                                    <div className="price-properties footer pt-3 pb-0">
                                        <h3 className="title mt-3">
                                            <a href={`/property/${property._id}`}>{property.content.price.amount.toLocaleString('en-US')} {property.content.price.currency}</a>
                                        </h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="bg-all">
                    <a href="/properties" className="btn btn-outline-light">View More</a>
                </div>
            </div>
        </section>

    )
}
export default FeaturedProperties;