import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { FilterPropertiesService, getLocations, getLocationsFind } from '../Services/Serv-properties';
import { Autocomplete, Pagination, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PropertiesFilter from './home/properties-filter';
import Partners from './partners';

const minDistance = 10;

const Listing: React.FunctionComponent<{}> = () => {

    const [isChange, setIsChange] = useState<boolean>(false)
    const [filterProperty, setFilterProperty] = useState<any>();
    const [listProperties, setListProperties] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>();
    const navigate = useNavigate();

    useEffect(() => {
        FilterPropertiesService(page, filterProperty).then((data) => {
            setPage(Math.ceil(data.totalItems / 8))
            setTotal(data.totalItems)
            setListProperties(data.properties)
            setLoading(false)
        }).catch((error) => {
            console.log("error")
        })

    }, [isChange])
 
    const SearchHandler = (objValue :any) => {
        setFilterProperty(objValue)
        FilterPropertiesService(1, objValue).then((data) => {
            if (data.totalItems == 0) {
                setTotal(data.totalItems)
                setPage(1)
            } else {
                setTotal(data.totalItems)
                setPage(Math.ceil(data.totalItems / 8))
            }
            setListProperties(data.properties)

        }).catch((error) => {
            console.log("error")
        })
    }

    const handleChangeSortBy = (event: any) => {
        setFilterProperty((prevOptions: any) => ({
            ...prevOptions,
            sort_by: event.target.value,
        }));
        setIsChange(!isChange)
    }

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        FilterPropertiesService(value, filterProperty).then((data) => {
            setTotal(data.totalItems)
            setPage(Math.ceil(data.totalItems / 8))
            setListProperties(data.properties)
        }).catch(err => {

        })

    };

    return (
        <div>
            <PropertiesFilter onSearchProperties={SearchHandler}/>

            <div className="inner-pages homepage-4 agents list  full hd-white ">

                <section className="properties-list full featured portfolio blog">
                    <div className="container">
                        <section className="headings-2 pt-0 pb-0">
                            <div className="pro-wrapper">
                                <div className="detail-wrapper-body">
                                    <div className="listing-title-bar">
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <div className="col-12 px-0 parallax-searchs">
                        <div className="banner-search-wrap">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tabs_1">
                                    <div className="rld-main-search" style={{padding:"0px"}}>
                                        <div className="row">

                                            <div className="col-lg-3 col-md-3 col-xs-12 rld-single-select  mb-3">
                                                <FormControl fullWidth>
                                                    <Select
                                                        sx={{ height: "48px", background: "#fff", width: "100%", marginRight: "15px" }}
                                                        className="select"
                                                        value={filterProperty?.category || ""}
                                                        onChange={handleChangePropertyType}
                                                        displayEmpty
                                                        color='error'
                                                        inputProps={{ 'aria-label': 'Select' }}
                                                    >
                                                        <MenuItem value="">Property Type</MenuItem>
                                                        <MenuItem value={"Apartment"}>Apartment</MenuItem>
                                                        <MenuItem value={"Villa"}>Villa</MenuItem>
                                                        <MenuItem value={"Townhouse"}>Townhouse</MenuItem>
                                                        <MenuItem value={"Penthouse"}>Penthouse</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className=" col-lg-4 col-md-4 col-xs-12 rld-single-select  mb-3">
                                                <Autocomplete
                                                    freeSolo
                                                    sx={{ background: "#fff", width: "100%", height: "48px" }}
                                                    value={locationValue}
                                                    onChange={handleChangeLocation}
                                                    options={initialLocations.map((location: any) => location.LOCATION || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="City, community or building "
                                                            onChange={handleSearchLocation}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-xs-12 mb-3" >
                                                <div className='dropdown-Search' onClick={() => { setShowAdvancedSearch(!showAdvancedSearch) }}>
                                                    <span >Advanced Search</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-xs-12  mb-3" >
                                                <a className="btn btn-yellow" style={{ width: "100%" }} onClick={Search}>Search Now</a>
                                            </div>
                                            {showAdvancedSearch && <div className="explore__form-checkbox-list full-filter filter-block">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 py-1 pr-30 pl-0">

                                                        <div className="form-group categories">
                                                            <div className="nice-select form-control wide"><span className="current"><i className="fa fa-home"></i>Property Status</span>

                                                                <ul className="list" >
                                                                    <li value="for-sale" className="option" onClick={handleChangeStatus}>For Sale</li>
                                                                    <li value="for-rent" className="option" onClick={handleChangeStatus}>For Rent</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 py-1 pr-30 pl-0 ">
                                                        <div className="form-group beds">
                                                            <div className="nice-select form-control wide"><span className="current"><i className="fa fa-bed" aria-hidden="true"></i> Bedrooms</span>
                                                                <ul className="list" >
                                                                    <li value={1} className="option" onClick={handleChangeBedrooms}>1</li>
                                                                    <li value={2} className="option" onClick={handleChangeBedrooms}>2</li>
                                                                    <li value={3} className="option" onClick={handleChangeBedrooms}>3</li>
                                                                    <li value={4} className="option" onClick={handleChangeBedrooms}>4</li>
                                                                    <li value={5} className="option" onClick={handleChangeBedrooms}>5</li>
                                                                    <li value={6} className="option" onClick={handleChangeBedrooms}>6</li>
                                                                    <li value={7} className="option" onClick={handleChangeBedrooms}>7</li>
                                                                    <li value={8} className="option" onClick={handleChangeBedrooms}>8</li>
                                                                    <li value={9} className="option" onClick={handleChangeBedrooms}>9</li>
                                                                    <li value={10} className="option" onClick={handleChangeBedrooms}>10</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 py-1 pl-0 pr-0">
                                                        <div className="form-group bath">
                                                            <div className="nice-select form-control wide"><span className="current"><i className="fa fa-bath" aria-hidden="true"></i> Bathrooms</span>
                                                                <ul className="list" onChange={handleChangeBathrooms}>
                                                                    <li value={1} className="option" onClick={handleChangeBathrooms}>1</li>
                                                                    <li value={2} className="option" onClick={handleChangeBathrooms}>2</li>
                                                                    <li value={3} className="option" onClick={handleChangeBathrooms}>3</li>
                                                                    <li value={4} className="option" onClick={handleChangeBathrooms}>4</li>
                                                                    <li value={5} className="option" onClick={handleChangeBathrooms}>5</li>
                                                                    <li value={6} className="option" onClick={handleChangeBathrooms}>6</li>
                                                                    <li value={7} className="option" onClick={handleChangeBathrooms}>7</li>
                                                                    <li value={8} className="option" onClick={handleChangeBathrooms}>8</li>
                                                                    <li value={9} className="option" onClick={handleChangeBathrooms}>9</li>
                                                                    <li value={10} className="option" onClick={handleChangeBathrooms}>10</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-12 col-sm-12 py-1 pr-30 mr-5 sld">
                                                        <div className="main-search-field-2">
                                                            <div className="range-slider">
                                                                <label>Area Size</label>
                                                                <Slider
                                                                    getAriaLabel={() => 'Minimum distance shift'}
                                                                    value={areaSize}
                                                                    onChange={handleChangeAreaize}
                                                                    min={0}
                                                                    max={1300}
                                                                    sx={{ color: "#FF385C" }}
                                                                    color="error"
                                                                    disableSwap
                                                                />
                                                                <input type="text" className="first-slider-value" value={areaSize[0] + " " + "Sq Ft"} style={{ marginTop: "-4px" }} />
                                                                <input type="text" className="second-slider-value" value={areaSize[1] + " " + "Sq Ft"} style={{ marginTop: "-4px" }} />
                                                                <div className="clearfix"></div>
                                                            </div>
                                                            <br />
                                                            <div className="range-slider">
                                                                <label>Price Range</label>
                                                                <Slider
                                                                    getAriaLabel={() => 'Minimum distance shift'}
                                                                    value={price}
                                                                    onChange={handleChangePrice}
                                                                    min={0}
                                                                    max={600000}
                                                                    sx={{ color: "#FF385C" }}
                                                                    disableSwap
                                                                />
                                                                <input type="text" className="first-slider-value" value={"$" + " " + price[0]} style={{ marginTop: "-4px" }} />
                                                                <input type="text" className="second-slider-value" value={"$" + " " + price[1]} style={{ marginTop: "-4px" }} />
                                                                <div className="clearfix"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-12 py-1 pr-30">
                                                        <div className="checkboxes one-in-row margin-bottom-10 ch-1">
                                                            <input id="check-2" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Air Conditioning") }} checked={selectAmenities.includes("Air Conditioning")} />
                                                            <label htmlFor="check-2">Air Conditioning</label>
                                                            <input id="check-3" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Swimming Pool") }} checked={selectAmenities.includes("Swimming Pool")} />
                                                            <label htmlFor="check-3">Swimming Pool</label>
                                                            <input id="check-4" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Central Heating") }} checked={selectAmenities.includes("Central Heating")} />
                                                            <label htmlFor="check-4">Central Heating</label>
                                                            <input id="check-5" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Laundry Room") }} checked={selectAmenities.includes("Laundry Room")} />
                                                            <label htmlFor="check-5">Laundry Room</label>
                                                            <input id="check-6" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Gym") }} checked={selectAmenities.includes("Gym")} />
                                                            <label htmlFor="check-6">Gym</label>
                                                            <input id="check-7" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Alarm") }} checked={selectAmenities.includes("Alarm")} />
                                                            <label htmlFor="check-7">Alarm</label>
                                                            <input id="check-8" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Window Covering") }} checked={selectAmenities.includes("Window Covering")} />
                                                            <label htmlFor="check-8">Window Covering</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-12 py-1 pr-30">
                                                        <div className="checkboxes one-in-row margin-bottom-10 ch-2">
                                                            <input id="check-9" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("WiFi") }} checked={selectAmenities.includes("WiFi")} />
                                                            <label htmlFor="check-9">WiFi</label>
                                                            <input id="check-10" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("TV Cable") }} checked={selectAmenities.includes("TV Cable")} />
                                                            <label htmlFor="check-10">TV Cable</label>
                                                            <input id="check-11" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Dryer") }} checked={selectAmenities.includes("Dryer")} />
                                                            <label htmlFor="check-11">Dryer</label>
                                                            <input id="check-12" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Microwave") }} checked={selectAmenities.includes("Microwave")} />
                                                            <label htmlFor="check-12">Microwave</label>
                                                            <input id="check-13" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Washer") }} checked={selectAmenities.includes("Washer")} />
                                                            <label htmlFor="check-13">Washer</label>
                                                            <input id="check-14" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Refrigerator") }} checked={selectAmenities.includes("Refrigerator")} />
                                                            <label htmlFor="check-14">Refrigerator</label>
                                                            <input id="check-15" type="checkbox" name="check" onChange={(event) => { handleChangeAmenities("Outdoor Shower") }} checked={selectAmenities.includes("Outdoor Shower")} />
                                                            <label htmlFor="check-15">Outdoor Shower</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                        <section className="headings-2 pt-0">
                            <div className="pro-wrapper">
                                <div className="detail-wrapper-body">
                                    <div className="listing-title-bar">
                                        <div className="text-heading text-left">
                                            <p className="font-weight-bold mb-0 mt-3">{total && total} Search results</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cod-pad single detail-wrapper mt-0 d-flex justify-content-md-end align-items-center" style={{ marginLeft: "0px" }}>
                                    <div className="input-group border rounded input-group-lg w-auto">
                                        <label className="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3" htmlFor="inputGroupSelect01"><i className="fas fa-align-left fs-16 pr-2"></i>Sort by:</label>
                                        <FormControl >
                                            <Select
                                                className="form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby"
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Select' }}
                                                sx={{ border: "0px !important", marginLeft: "15px", minWidth: "100px" }}
                                                onChange={handleChangeSortBy}
                                                color="error"
                                            >
                                                <MenuItem value="low to high">Price(low to high)</MenuItem>
                                                <MenuItem value="high to low">Price(high to low)</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </div>

                                </div>
                            </div>
                        </section>
                        {loading ? (<div className="row featured portfolio-items"> Loading ...</div>) :
                            <><div className="row featured portfolio-items">
                                {listProperties && listProperties.map((property: any, index: any) => {
                                    return <>
                                        <div key={index} className="item col-lg-4 col-md-12 col-xs-12 landscapes sale pr-0 pb-0" style={{ marginBottom: "30px" }} onClick={() => { navigate(`/property/${property._id}`) }}>
                                            <div className="project-single mb-0 bb-0">
                                                <div className="project-inner project-head">
                                                    <div className="homes">
                                                        <a href={`/property/${property._id}`} className="homes-img">
                                                            <div className="homes-tag button sale rent">{property.content.offeringType.replace(/-/g, ' ').replace(/\b\w/g, (c: any) => c.toUpperCase())}</div>
                                                            <img src={property.content.images[0]} alt="home-1" className="img-responsive" style={{ height: "280px" }} />
                                                        </a>
                                                    </div>
                                                    <div className="button-effect">
                                                        <a href={`/property/${property._id}`} className="img-poppu btn"><i className="fa fa-photo"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-12 homes-content pb-0 mb-44" style={{ marginBottom: "30px" }}>
                                            <h3><a href={`/property/${property._id}`}>{property.content.title}</a></h3>
                                            <p className="homes-address mb-3">
                                                <a href={`/property/${property._id}`}>
                                                    <i className="fa fa-map-marker"></i><span>{property.content.Address.subCommunity}, {property.content.Address.community}, {property.content.Address.city}</span>
                                                </a>
                                            </p>
                                            <ul className="homes-list clearfix pb-3">
                                                <li className="the-icons">
                                                    <i className="flaticon-bed mr-2" aria-hidden="true"></i>
                                                    <span>{property.content.bedrooms_count} Bedrooms</span>
                                                </li>
                                                <li className="the-icons">
                                                    <i className="flaticon-bathtub mr-2" aria-hidden="true"></i>
                                                    <span>{property.content.bathrooms_count} Bathrooms</span>
                                                </li>
                                                <li className="the-icons">
                                                    <i className="flaticon-square mr-2" aria-hidden="true"></i>
                                                    <span>{property.content.size.value} {property.content.size.unit}</span>
                                                </li>
                                                <li className="the-icons">
                                                    <span style={{ fontWeight: "600", fontSize: "18px" }}>{property.content.price.amount.toLocaleString('en-US')} {property.content.price.currency}</span>
                                                </li>
                                            </ul>
                                            <div className="footer">
                                                <a href="agent-details.html">
                                                    <img src={property.agent.photo} alt="" className="mr-2" /> {property.agent.name}
                                                </a>

                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                                {total !== 0 ? <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px" }} onChange={handleChangePagination} />
                                    : <p style={{ textAlign: "center" }}>No listing</p>
                                }</>
                        }
                    </div>
                </section>
            </div>
            <Partners/>
        </div>
    )
}
export default Listing;