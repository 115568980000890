

const AgentInformation: React.FunctionComponent<{ agent: any }> = (props) => {

    return (
        <aside className="col-lg-4 col-md-12 car">
            <div className="single widget">
                <div className="sidebar">
                    <div className="widget-boxed mt-33 ">
                        <div className="widget-boxed-header">
                            <h4>Agent Information</h4>
                        </div>
                        <div className="widget-boxed-body">
                            <div className="sidebar-widget author-widget2">
                                <div className="author-box clearfix">
                                    <img src={props.agent.photo} alt="author-image" className="author__img" />
                                    <h4 className="author__title">{props.agent.name}</h4>
                                    <p className="author__meta">Agent of {props.agent.companyName}</p>
                                </div>
                                <ul className="author__contact">
                                    <li><span className="la la-phone"><i className="fa fa-phone" aria-hidden="true"></i></span><a href={`tel:${props.agent.phoneNumbers}`}>{props.agent.phoneNumbers}</a></li>
                                    <li><span className="la la-envelope-o"><i className="fa fa-envelope" aria-hidden="true"></i></span><a href={`mailto:${props.agent.emailAddresses}`}>{props.agent.emailAddresses}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </aside>
    )
}
export default AgentInformation;