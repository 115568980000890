
import Marquee from "react-fast-marquee";

const Partners: React.FunctionComponent<{}> = (props) => {


    return (
        <div className="homepage-9 hp-6 homepage-1 mh">
        <div className="partners bg-white rec-pro" >
            <div className="container-fluid">
                <div className="sec-title">
                    <h2><span>Our </span>Partners</h2>
                    <p>The Companies That Represent Us.</p>
                </div>
                <div className="style2">
                    <Marquee pauseOnClick={true}>
                        <div className="owl-item"><img src="/assets/images/partners/bayut-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/coralytics-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/dubizzle-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/property-finder-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/starzplay-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/aldar-properties-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/bayut-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/coralytics-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/dubizzle-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/property-finder-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/starzplay-logo.png" alt="" /></div>
                        <div className="owl-item"><img src="/assets/images/partners/aldar-properties-logo.png" alt="" /></div>
                    </Marquee>
                </div>

            </div>
        </div >
        </div>
    )
}
export default Partners;