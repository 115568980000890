
const SimilarProperties: React.FunctionComponent<{listProperties :any}> = (props) => {


    return (
        <section className="similar-property featured portfolio p-0 bg-white-inner mt-3">
          <div className="container">
                <h5>Similar Properties</h5>
                <div className="row portfolio-items">
                {props.listProperties && props.listProperties.map((property: any, index: any) => {
                  return  <div className="item col-lg-4 col-md-6 col-xs-12 people">
                        <div className="project-single">
                            <div className="project-inner project-head">
                                <div className="homes">

                                    <a  href={`/property/${property._id}`}  className="homes-img">
                                        <div className="homes-tag button sale rent">{property.content.offeringType.replace(/-/g, ' ').replace(/\b\w/g, (c: any) => c.toUpperCase())}</div>
                                        <div className="homes-price">{property.content.price.amount.toLocaleString('en-US')}{property.content.price.currency}</div>
                                        <img src={property.content.images[0]} alt="home-1" style={{minHeight:"250px"}} className="img-responsive" />
                                    </a>
                                </div>
                                <div className="button-effect">
                                    
                                    <a  href={`/property/${property._id}`}  className="img-poppu btn"><i className="fa fa-photo"></i></a>
                                </div>
                            </div>

                            <div className="homes-content">

                                <h3><a  href={`/property/${property._id}`} >{property.content.title}</a></h3>
                                <p className="homes-address mb-3">
                                    <a  href={`/property/${property._id}`} >
                                        <i className="fa fa-map-marker"></i><span>{property.content.Address.subCommunity}, {property.content.Address.community}, {property.content.Address.city}</span>
                                    </a>
                                </p>

                                <ul className="homes-list clearfix pb-3">
                                    <li className="the-icons">
                                        <i className="flaticon-bed mr-2" aria-hidden="true"></i>
                                        <span>{property.content.bedrooms_count} Bedrooms</span>
                                    </li>
                                    <li className="the-icons">
                                        <i className="flaticon-bathtub mr-2" aria-hidden="true"></i>
                                        <span>{property.content.bathrooms_count} Bathrooms</span>
                                    </li>
                                    <li className="the-icons">
                                        <i className="flaticon-square mr-2" aria-hidden="true"></i>
                                        <span>{property.content.size.value} {property.content.size.unit}</span>
                                    </li>
                                   
                                </ul>
                                <div className="footer">
                                    <a  href={`/property/${property._id}`} >
                                        <img  src={property.agent.photo}  alt="" className="mr-2" />  {property.agent.name}
                                    </a>
                                    {/* <span>2 months ago</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    })}
                </div>
            </div>
        </section>
    )


}
export default SimilarProperties;