import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonials: React.FunctionComponent<{}> = () => {


    return (
        <section className="testimonials bg-white-2 rec-pro">
            <div className="container-fluid">
                <div className="sec-title">
                    <h2><span>Clients </span>Testimonials</h2>
                    <p>We collect reviews from our customers.</p>
                </div>
                <OwlCarousel className='row  job_clientSlide-web owl-theme' loop >
                    <div className=" item singleJobClinet " style={{ minHeight: "350px" }}>
                        <p>
                            Working with Nassira Properties has been an exceptional experience. Their expertise in luxury real estate is unmatched, and their dedication to women empowerment is truly inspiring. They managed my property with the utmost professionalism and ensured I received the best returns on my investment. I highly recommend Nassira Properties to any property owner seeking top-tier management services.                        </p>
                        <div className="detailJC">
                            <h5>Amina K </h5>
                        </div>
                    </div>

                    <div className=" item singleJobClinet " style={{ minHeight: "350px" }}>
                        <p>
                            I am incredibly impressed with the service provided by Nassira Properties. Their team, led by visionary women, is knowledgeable, attentive, and committed to excellence. They took great care in managing my luxury property and kept me informed throughout the process. It's refreshing to work with a company that values both its clients and women's empowerment.
                        </p>
                        <div className="detailJC">
                            <h5>Omar S</h5>
                        </div>
                    </div>

                    <div className=" item singleJobClinet " style={{ minHeight: "350px" }}>
                        <p>
                            Nassira Properties made selling my luxury home an effortless and rewarding experience. Their marketing strategy was innovative and effective, attracting many potential buyers quickly. The entire team, driven by a vision of women empowerment, was professional and supportive. Thanks to their hard work, I secured an excellent price for my property.
                        </p>
                        <div className="detailJC">
                          
                            <h5>Fatima R</h5>
                        </div>
                    </div>

                    <div className=" item singleJobClinet " style={{ minHeight: "350px" }}>
                        <p>
                        Choosing Nassira Properties to sell my home was the best decision I made. Their expertise in luxury real estate, coupled with their commitment to empowering women, set them apart. They handled everything with precision and care, and their negotiation skills were exceptional. I am thrilled with the outcome and highly recommend their services.                        </p>
                        <div className="detailJC">
                          
                            <h5>Khalid M</h5>
                        </div>
                    </div>
                    <div className=" item singleJobClinet " style={{ minHeight: "350px" }}>
                        <p>
                        Finding my dream home with Nassira Properties was an outstanding experience. Their team, led by inspiring women, understood my needs and preferences and showed me the perfect luxury properties. They were patient, knowledgeable, and always available to answer my questions. I am grateful for their dedication and highly recommend Nassira Properties to anyone looking to buy a luxury home.                        </p>
                        <div className="detailJC">
                          
                            <h5>Layla H</h5>
                        </div>
                    </div>
                    <div className=" item singleJobClinet " style={{ minHeight: "350px" }}>
                        <p>
                        Buying a luxury home through Nassira Properties was a delightful and seamless process. Their commitment to women empowerment and their expertise in the high-end market truly stood out. They guided me through every step, from viewing properties to closing the deal. Thanks to their exceptional service, I am now living in my dream home.                        </p>
                        <div className="detailJC">
                          
                            <h5>Yasir T</h5>
                        </div>
                    </div>

                </OwlCarousel>

                <div className='row job_clientSlide-mobile owl-theme'>
                <div className=" item singleJobClinet ">
                        <p>
                            Working with Nassira Properties has been an exceptional experience. Their expertise in luxury real estate is unmatched, and their dedication to women empowerment is truly inspiring. They managed my property with the utmost professionalism and ensured I received the best returns on my investment. I highly recommend Nassira Properties to any property owner seeking top-tier management services.                        </p>
                        <div className="detailJC">
                            <h5>Amina K </h5>
                        </div>
                    </div>

                    <div className=" item singleJobClinet ">
                        <p>
                            I am incredibly impressed with the service provided by Nassira Properties. Their team, led by visionary women, is knowledgeable, attentive, and committed to excellence. They took great care in managing my luxury property and kept me informed throughout the process. It's refreshing to work with a company that values both its clients and women's empowerment.
                        </p>
                        <div className="detailJC">
                            <h5>Omar S</h5>
                        </div>
                    </div>

                    <div className=" item singleJobClinet ">
                        <p>
                            Nassira Properties made selling my luxury home an effortless and rewarding experience. Their marketing strategy was innovative and effective, attracting many potential buyers quickly. The entire team, driven by a vision of women empowerment, was professional and supportive. Thanks to their hard work, I secured an excellent price for my property.
                        </p>
                        <div className="detailJC">
                          
                            <h5>Fatima R</h5>
                        </div>
                    </div>

                    <div className=" item singleJobClinet ">
                        <p>
                        Choosing Nassira Properties to sell my home was the best decision I made. Their expertise in luxury real estate, coupled with their commitment to empowering women, set them apart. They handled everything with precision and care, and their negotiation skills were exceptional. I am thrilled with the outcome and highly recommend their services.                        </p>
                        <div className="detailJC">
                          
                            <h5>Khalid M</h5>
                        </div>
                    </div>
                    <div className=" item singleJobClinet ">
                        <p>
                        Finding my dream home with Nassira Properties was an outstanding experience. Their team, led by inspiring women, understood my needs and preferences and showed me the perfect luxury properties. They were patient, knowledgeable, and always available to answer my questions. I am grateful for their dedication and highly recommend Nassira Properties to anyone looking to buy a luxury home.                        </p>
                        <div className="detailJC">
                          
                            <h5>Layla H</h5>
                        </div>
                    </div>
                    <div className=" item singleJobClinet ">
                        <p>
                        Buying a luxury home through Nassira Properties was a delightful and seamless process. Their commitment to women empowerment and their expertise in the high-end market truly stood out. They guided me through every step, from viewing properties to closing the deal. Thanks to their exceptional service, I am now living in my dream home.                        </p>
                        <div className="detailJC">
                          
                            <h5>Yasir T</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default Testimonials;
