import axios from "axios";

const Url = process.env.REACT_APP_URl;


export const FilterPropertiesService = async (page :any,properties: any,) => {
    const apiUrl = Url + "/paginate";
    const response = await axios.post(apiUrl,{ "page": page, "limit": 8, "filters": properties},{
      headers: {
        "Authorization": "Bearer "+""
      }
    })
    return response.data;
  }
  export const GetPropertyDetails = async (id: any,) => {
    const apiUrl = Url + "/"+id;
    const response = await axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+""
      }
    })
    return response.data;
  }
  export const getLocations=async(locationName: any)=>{
    const apiUrl =  "https://www.api-v1.coralytics.com/locations/autocomplete";
    const response = await axios.post(apiUrl,{locationName :locationName},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const  GetFeaturedProperties=async()=>{
    const apiUrl = Url + "/featured";
    const response = await axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const  GetSimilarProperties=async(id:any)=>{
    const apiUrl = Url + "/similar-properties";
    const response = await axios.get(`${apiUrl}/${id}`,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const  GetAgents=async()=>{
    const apiUrl = Url + "/agentslist";
    const response = await axios.post(apiUrl,{},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const getLocationsFind=async(location:any)=>{
    const apiUrl =   "https://www.api-v1.coralytics.com/locations/find";
    const response = await axios.post(apiUrl,{location:location},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }