
const Footer: React.FunctionComponent<{}> = () => {

    return (
        <>
            <footer className="first-footer rec-pro">
                <div className="second-footer rec-pro">
                    <div className="container-fluid sd-f" style={{ display: "flex", justifyContent: "space-between" }}>
                        <p>2024 © Copyright - All Rights Reserved.</p>
                        <ul className="netsocials">
                   
                            <li style={{marginRight:"20px"}}><a href="https://www.instagram.com/snassira/?hl=en" target="_blank"><i className="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </footer>
            <a data-scroll href="#wrapper" className="go-up"><i className="fa fa-angle-double-up" aria-hidden="true"></i></a>
        </>
    )

}

export default Footer;