
import { useEffect, useState } from "react"


const PropertiesMediaKit: React.FunctionComponent<{}> = (props) => {



    return (
        <div className="homepage-9 hp-6 homepage-1 mh mediakit">

            <section className="how-it-works bg-white rec-pro  bg-green-2">
                <div className="container-fluid padd-service-profile">
                    <div className="service-profile">
                        <img src="/assets/images/Media -Kit.png" className="img-profile" />
                        <div className="box">
                            <h2 className="title">Nassira Sekkay</h2>
                            <p>Nassira has been coined ‘The Shark’ which she took her Instagram to state that “Being a « shark » in real estate means being super determined, like a superhero. It’s like always trying your best and never giving up, no matter what. Just like how sharks never stop chasing their food, we never stop working hard.” Ultimately, conveying Nassira’s owns drive and ambition when it comes to her career.</p>
                            <h3 className="title-h3">As know from: </h3>
                            <div>
                                <img src="/assets/images/listing.png" className="img-1" />
                                <img src="/assets/images/Black-and-White-Modern.png" className="img-2" />
                                <img src="/assets/images/arabe-news.png" className="img-3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" how-it-works bg-white rec-pro  bg-green-2">
                <div className="container-fluid padd-service-profile">
                    <div className="service-profile-2">
                        <h2 className="title-h2">Who is Nassira Sekkay?</h2>
                        <div className="row">
                            <div className="item col-lg-4 col-md-4 col-xs-12 people">
                                <div className="project-single">
                                    <div className="project-inner project-head">
                                        <div className="homes">
                                            <a className="homes-img">
                                                <img src="/assets/images/prof-img-2-1.png" alt="home-1" style={{ minHeight: "250px" }} className="img-responsive" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="homes-content">
                                        <h3 className="title-h3">Wonder Woman of Real <br />Estate</h3>
                                        <p>Nassira Sekkay has an extensive
                                            experience in the luxury real
                                            estate market in Dubai. She is a
                                            successful businesswoman who
                                            has built an impeccable
                                            reputation in the industry and
                                            currently dominates the area
                                            District One and extends her business to other luxury areas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item col-lg-4 col-md-4 col-xs-12 people">
                                <div className="project-single">
                                    <div className="project-inner project-head">
                                        <div className="homes">
                                            <a className="homes-img">
                                                <img src="/assets/images/prof-img-2-2.png" alt="home-1" style={{ minHeight: "250px" }} className="img-responsive" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="homes-content">
                                        <h3 className="title-h3">Global Citizen with big <br />ambitions</h3>
                                        <p>Nassira is fluent in three
                                            languages and understands the
                                            intricacies of her accomplished
                                            clients that come from all across
                                            the globe. In the coming months,
                                            she will extend her Brokerage
                                            from Dubai to Abu Dhabi and
                                            wants to empower many Females to achieve their dreams.  </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item col-lg-4 col-md-4 col-xs-12 people">
                                <div className="project-single">
                                    <div className="project-inner project-head">
                                        <div className="homes">
                                            <a className="homes-img">
                                                <img src="/assets/images/prof-img-2-3.png" alt="home-1" style={{ minHeight: "250px" }} className="img-responsive" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="homes-content">
                                        <h3 className="title-h3">WCommunity Leader for <br />young Professionals</h3>
                                        <p>Nassira is dedicated to
                                            empowering her community and
                                            believes anyone can succeed, no
                                            matter where they come from.
                                            Furthermore she is highly
                                            engaged in several charity
                                            programs in order to live up to her
                                            religious values and give back to others. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-it-works bg-white rec-pro  bg-green-2">
                <div className="container-fluid padd-service-profile">
                    <div className="service-profile-3">
                        <h2 className="title-h2">Why Collaborating with Nassira will benefit your brand ? </h2>
                        <p className="para">Collaborating with Nassira Properties isn't just about exposure; it's about connecting with a sophisticated
                            audience, leveraging our media reach, and becoming a part of an exclusive network that values luxury,
                            authenticity, and excellence.</p>
                        <div className="row">
                            <div className="item col-lg-4 col-md-4 col-xs-12 people">
                                <div className="project-single">
                                    <div className="homes-content">
                                        <span>1</span>
                                        <div className="text-content">
                                            <h3 className="title-h3">Upcoming MENA Media Reach</h3>
                                            <p>Through her presence in
                                                the upcoming hit show
                                                "Million Dollar Listings"
                                                brands will gain access to a
                                                rapidly growing media
                                                reach in the MENA (Middle
                                                East and North Africa)
                                                region. Our presence and
                                                influence in this dynamic
                                                market allow brands to
                                                connect with a diverse and
                                                engaged audience,
                                                expanding their reach and
                                                impact.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item col-lg-4 col-md-4 col-xs-12 people">
                                <div className="project-single">
                                    <div className="homes-content">
                                        <span>2</span>
                                        <div className="text-content">
                                            <h3 className="title-h3">Exclusive Exposure trough Image Nation</h3>
                                            <p>Nassira is proud to partner
                                                with Image Nation, a
                                                prominent player in the
                                                media and entertainment
                                                industry with a 420 Million
                                                Dollar valuation. This
                                                collaboration opens doors
                                                for brands to gain exclusive
                                                exposure through our
                                                media projects, ensuring
                                                their products or services
                                                are showcased in high-
                                                profile and culturally
                                                significant content.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item col-lg-4 col-md-4 col-xs-12 people">
                                <div className="project-single">
                                    <div className="homes-content">
                                        <span>3</span>
                                        <div className="text-content">
                                            <h3 className="title-h3">High End VIP Clients and Followers</h3>
                                            <p>When brands collaborate
                                                with Nassira Properties,
                                                they instantly tap into our
                                                exclusive VIP network. Our
                                                Ultra-High Net Worth
                                                Individual (UHNI) clients
                                                and dedicated followers
                                                are discerning and
                                                influential individuals who
                                                value luxury and
                                                excellence. Partnering with
                                                us ensures that your brand
                                                gains visibility and
                                                recognition among this
                                                elite clientele.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-it-works bg-white rec-pro  bg-green-2">
                <div className="container-fluid padd-service-profile">
                    <div className="service-profile-4">
                        <img src="/assets/images/prof-img-3-1.png" className="img-profile" />
                        <div className="box">
                            <h2 className="title">Nassira's Social Media Channels</h2>
                            <p>Stay up-to-date with Nassira Sekkay and her team by
                                following them on their social media channels. You'll get
                                access to exclusive content, behind-the-scenes glimpses, and
                                more.</p>
                            <p>Trough her presence in the show we expect a rapid social
                                media channel growth in the next few weeks. Furthermore we
                                plan a SMM campaign on our own, aligned with Starzplay and
                                Image Nation to launch her company Nassira Properties and
                                scale her personal brand. </p>
                            <p>Through this campaign, we aim to increase brand recognition
                                for Nassira Sekkay and her company on social media. By
                                partnering with Starzplay and Image Nation, we are confident
                                that we can showcase the quality and luxury that Nassira
                                Properties can offer to potential clients and followers. We are
                                excited to see the growth and success that this SMM
                                campaign will bring.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-it-works bg-white rec-pro  bg-green-2">
                <div className="container-fluid padd-service-profile">
                    <div className="service-profile-6">
                        <div className="row">
                            <div className="item col-lg-6 col-md-6 col-xs-12 people" >
                                <div className="project-single">
                                    <a className="project-inner project-head" href="https://www.harpersbazaararabia.com/culture/people/who-is-nassira-sekkay-million-dollar-listing-uae" target="_blank">
                                        <div className="homes">
                                            <img src="/assets/images/blog-1.webp" alt="home-1" className="img-responsive" />
                                        </div>
                                    </a>
                                    <a className="homes-content" href="https://www.harpersbazaararabia.com/culture/people/who-is-nassira-sekkay-million-dollar-listing-uae" target="_blank">
                                        <h3 className="title-h3">Who Is Nassira Sekkay, An Agent On The Million Dollar Listing UAE? </h3>
                                    </a>
                                </div>
                            </div>
                            <div className="item col-lg-6 col-md-6 col-xs-12 people">
                                <div className="project-single">
                                    <a className="project-inner project-head" href="https://www.arabnews.com/node/2373591/lifestyle" target="_blank">
                                        <div className="homes">
                                            <div className="homes-img">
                                                <img src="/assets/images/blog-2.webp" alt="home-1" className="img-responsive" />
                                            </div>
                                        </div>
                                    </a>
                                    <a className="homes-content" href="https://www.arabnews.com/node/2373591/lifestyle" target="_blank">
                                        <h3 className="title-h3">‘Million Dollar Listing UAE’ stars on why the Mideast version of the hit US show could be the best</h3>
                                    </a>
                                </div>
                            </div>
                            <div className="item col-lg-6 col-md-6 col-xs-12 people">
                                <div className="project-single">
                                    <a className="project-inner project-head" href="https://gulfnews.com/entertainment/tv/hit-reality-series-million-dollar-listing-uae-to-debut-this-month-1.97841623" target="_blank">
                                        <div className="homes">
                                            <div className="homes-img">
                                                <img src="/assets/images/blog-3.webp" alt="home-1" className="img-responsive" />
                                            </div>
                                        </div>
                                    </a>
                                    <a className="homes-content" href="https://gulfnews.com/entertainment/tv/hit-reality-series-million-dollar-listing-uae-to-debut-this-month-1.97841623" target="_blank">
                                        <h3 className="title-h3">Hit reality series ‘Million Dollar Listing UAE’ to debut this month</h3>
                                    </a>
                                </div>
                            </div>
                            <div className="item col-lg-6 col-md-6 col-xs-12 people">
                                <div className="project-single">
                                    <a className="project-inner project-head" href="https://www.factmagazines.com/entertainment/million-dollar-listing-uae-interview-nassira-sekkay" target="_blank">
                                        <div className="homes">
                                            <div className="homes-img">
                                                <img src="/assets/images/blog-4.webp" alt="home-1" style={{ height: "250px" }} className="img-responsive" />
                                            </div>
                                        </div>
                                    </a>
                                    <a className="homes-content" href="https://www.factmagazines.com/entertainment/million-dollar-listing-uae-interview-nassira-sekkay" target="_blank">
                                        <h3 className="title-h3">Million Dollar Listing UAE Interview: Nassira Sekkay</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-it-works bg-white rec-pro  bg-green-2">
                <div className="container-fluid padd-service-profile">
                    <div className="service-profile-5">
                        <img src="/assets/images/Container-back.png" />
                    </div>
                </div>
            </section>

        </div>
    )
}
export default PropertiesMediaKit;