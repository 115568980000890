import { Route, Routes } from 'react-router-dom';
import Layout from '../Component/Layout/layout';
import Listing from '../Component/listing';
import Property from '../Component/property';
import Home from '../Component/home/home';
import Contact from '../Component/contact';
import PropertiesMediaKit from '../Component/properties-media-kit';


const PublicRouter: React.FunctionComponent<{}> = (props) => {

    return (
        <>
            <Routes>
                <Route path='/' element={<Layout/>} >
                    <Route path="" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/contact-us" element={<Contact/>} />
                    <Route path="/listing/:queryParams" element={<Listing />} />
                    <Route path="/properties" element={<Listing />} />
                    <Route path="nassira-properties-media-kit" element={<PropertiesMediaKit/>}/>
                    <Route path="/property/:id" element={<Property />} />
                </Route>
            </Routes>
        </>
    )
}
export default PublicRouter;